import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../../images/logo.svg"

const Header = ({ siteTitle }) => (
  <>
    <header>
      <nav className="m-0 p-0  navbar navbar-expand-lg">
        <div className="container">
          <Link to="/">
            <img
              src={logo}
              alt="MassMediaCreatives"
              className="logo  m-0"
            ></img>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbar-toggler"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div
            className="collapse navbar-collapse header-menu"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav w-100 justify-content-end p-0 m-0 ">
              <li className="nav-item active">
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/aboutus">
                  About Us
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/portfolio">
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/clients">
                  Clients
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">
                  Contact Us
                </Link>
              </li>
          </ul>
          <ul class="navbar-nav">
              <li className="nav-item phone-test">
                <a
                   className="nav-link text-black phone-text "
                  href="https://api.whatsapp.com/send?phone=917305190548"
                  target="_blank"
                >
                  {" "}
                  +91 73051 90548
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
