import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faInstagram,
  faFacebookF,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"
const Header = ({ siteTitle }) => (
  <>
    <footer className="bg-main " id="footer">
      <div className="footer-top pt-5 pb-3">
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-header">
              <h4>Services</h4>
              <ul className="footer-links">
                <li>
                  <Link to="/services/graphic-design">Graphic Design</Link>
                </li>
                <li>
                  <Link to="/services/photography-videography">
                    Photography &amp; videography
                  </Link>
                </li>

                <li>
                  <Link to="/services/website-mobile-app-development">
                    Website &amp; Moble Application Development
                  </Link>
                </li>
                <li>
                  <Link to="/services/digital-marketing">
                    Digital Marketing
                  </Link>
                </li>

                <li>
                  <Link to="/services/advertising-promotional">
                    Advertishing &amp; Promotional MIX
                  </Link>
                </li>
                <li>
                  <Link to="/services/events-exhibitions">
                    Events &amp; Exhibitions
                  </Link>
                </li>
                <li>
                  <Link to="/services/printing-services">Printing</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links footer-header">
              <h4>MMC</h4>
              <ul>
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li>
                  <Link to="/clients">Clients</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-6 col-md-6 footer-linked footer-header">
              <h4>MASS MEDIA CREATIVES PVT.LTD</h4>
              <h4 className="py-2">Head Office</h4>
              <p className="social-links">
                #93,1st Floor,PeriyarPathai,Choolaimedu,Chennai-600094. <br />Landline Number : 044 - 23611988 / 044 - 42810608.
                <br /> Mail id : massmediacreatives@gmail.com, 
                <br />info@massmediacreatives.com.
              </p>
              <h4 className="py-2">Branch Office</h4>
              <p className="social-links ">
                #69,9th Main Road, 1st Cross, Begur Main Road,
                <br />
                Bommanahalli,Bengaluru - 560068.
                <br />Landline Number : 080 - 41470722,<br />Mobile Number : +91 98848 66818. 
              </p>

              <div className="social-linked mt-2 py-2">
                <span className="footer-follow">Follow on :</span>{" "}
                <a
                  href="https://www.facebook.com/massmediacreatives"
                  className="facebook socials-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} size="1x" />
                </a>
                <a
                  href="https://www.instagram.com/massmediacreatives/"
                  className="instagram socials-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} size="1x" />
                </a>
                <a
                  href="/"
                  className="twitter socials-icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} size="1x" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://api.whatsapp.com/send?phone=917305190548
         "
          className="whatsappfloat"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} size="1x" className="my-floats" />
        </a>
      </div>

      <div className="container footer-bottom py-2 d-flex justify-content-between">
        <div className="copyright ">
          &#169; {new Date().getFullYear()} &nbsp;
          <span>MASS MEDIA CREATIVES PVT</span>. All Rights Reserved
        </div>
        <div className="credits ">
          <a href="/">Terms and Conditions </a>&nbsp;&nbsp; |{" "}
          <a href="/"> Privacy Policy</a>
        </div>
      </div>
    </footer>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
